/*
 *  Document   : _contactgrid.scss
 *  Author     : RedStar Template
 *  Description: This scss file for style related to contact grid app
 */
.contact-grid {
  float: left;
  width: 100%;
  text-align: center;
}
.profile-header {
  min-height: 150px;
  color: #fff;
}
.user-name {
  padding: 3px;
  font-size: 22px;
  text-align: center;
  padding-top: 10px;
}
// .user-img {
//   padding: 3px;
//   border-radius: 50% 50% 50% 50%;
//   max-width: 112px;
//   margin-top: -70px;
//   box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
//   margin-bottom: 20px;
// }
.user-img {
  border-radius: 15px 15px;
  max-width: 28%;
  box-shadow: 0px 10px 25px 0px rgb(0 0 0 / 30%);
  margin-bottom: 18px;
  margin-top: -42px;
}
.profile-userbuttons {
  text-align: center;
  margin-top: 10px;
}
.contact-grid .phone .material-icons {
  font-size: 16px;
}
